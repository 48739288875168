.shell{
    padding-right: 10px;
}
.test:focus{
    outline-color : black;
    outline-width: 0px;
    caret-color: black;
}
.cursor{
    width: 15px;
    height: 17px;
    display: inline-block;
    margin-left: 1px;
    margin-bottom: -2px;
    background-color: rgb(51,255,51);
    animation: blink 1s infinite;
}

@keyframes blink{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
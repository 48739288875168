/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
  background-color: rgb(49, 47, 47);
  padding: 2px 10px 2px 10px;
} 

body{
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 2px;
  line-height: 1.5;
  color: rgb(51,255,51);

  /* background-color: black; */
  background-color: rgba(10, 10, 10, 0.6) !important;
  background-image: url('../assets/x-c.gif');
  background-blend-mode: color;
  background-size:cover;
  background-attachment: fixed;
}

.directory-name{
  color : #F7C527;
}
.file-name{
  color: #00AAFF;
}
.link-name{
  color: rgb(76, 197, 137);
  background-color: #9CF828;
  /* padding-right: 10px; */
  margin-right: 10px;
  padding: 0px 2px 2px 0px;
  color: white;

}
.error-msg{
  color: red;
}
a{
  text-decoration: none;
  color: black;
}